import "./SignIn.css";
import logo from "../../assets/images/hicoinylogo.jpg";
function DM3Logo() {
    return (
        /* eslint-disable max-len */
        <>
            <img src={logo} width="200px" style={{ borderRadius: "65px", alignSelf: "center" }} />
        </>
        /* eslint-enable max-len */
    );
}

export default DM3Logo;
